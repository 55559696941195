import Button from "./Button";

function InitialCard({ onClick }) {
	return (
		<div className="relative h-80">
			<h1 className="text-white text-center text-lg">
				Welcome to the <br></br> <b>L</b>eague of Legends Q<b>uiz</b>!
			</h1>
			<div className="flex justify-center">
				<img src="giphy.gif" width={200} />
			</div>
			<div className="flex justify-center">
				<Button onClick={onClick} text="Start Quiz" />
			</div>
		</div>
	);
}

export default InitialCard;
