function ChampionCardShow({ question }) {
	console.log(question);
	return (
		<div className="relative h-100 mb-9">
			<div className="flex justify-center">
				<img src={question.imageUrl} width={150} />
			</div>
			<div className="flex justify-center flex-col text-center">
				{question.options.map((option) => (
					<div
						className={`${
							question.answeredOption === option ? (question.answeredCorrectly ? "bg-green-500" : "bg-red-500") : question.correctOption === option ? "bg-green-500" : "bg-primary"
						} text-white text-center text-lg mt-2 p-2 px-4 rounded-lg w-1/2 mx-auto`}
						key={option}
					>
						{option}
					</div>
				))}
			</div>
		</div>
	);
}

export default ChampionCardShow;
