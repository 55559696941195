import { useEffect, useState } from "react";
import ChampionCard from "./ChampionCard";
import ChampionCardShow from "./ChampionCardShow";
import InitialCard from "./InitialCard";

function CardContainer({ questions }) {
	const [isStarted, setStarted] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [score, setScore] = useState(0);
	const [isFinished, setFinished] = useState(false);
	const [animation, setAnimation] = useState("");
	const [showFeedback, setShowFeedback] = useState(false);
	const [streak, setStreak] = useState(0);
	const [lastDate, setDate] = useState(0);
	const [isStreakBroken, setStreakBroken] = useState(false);
	const [isStreakIncremented, setStreakIncremented] = useState(false);
	const [alreadyPlayedToday, setAlreadyPlayedToday] = useState(false);

	useEffect(() => {
		const savedStreak = localStorage.getItem("streak");
		if (savedStreak) {
			setStreak(Number(savedStreak));
		}

		const savedDate = localStorage.getItem("lastDate");
		if (savedDate) {
			setDate(Number(savedDate));
		}
	}, []);

	const handleClick = () => {
		setStarted(true);
	};

	const handleAnswer = (event) => {
		const answer = event.target.innerText;
		const isCorrect = answer === questions[currentQuestion].correctOption;

		setAnimation(isCorrect ? "correct-animation" : "incorrect-animation");
		setShowFeedback(true);

		if (isCorrect) {
			setScore(score + 1);
			questions[currentQuestion].answeredCorrectly = true;
		}
		questions[currentQuestion].answered = true;
		questions[currentQuestion].answeredOption = answer;

		setTimeout(() => {
			if (currentQuestion === questions.length - 1) {
				const currentDate = new Date();
				const lastPlayedDate = new Date(lastDate);

				lastPlayedDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison
				currentDate.setHours(0, 0, 0, 0);

				const oneDayInMillis = 86400000; // 24 hours in milliseconds
				const twoDaysInMillis = 172800000; // 48 hours in milliseconds

				const timeSinceLastDate = currentDate.getTime() - lastPlayedDate.getTime();

				const isInRange = timeSinceLastDate >= oneDayInMillis && timeSinceLastDate < twoDaysInMillis;

				console.log(lastPlayedDate, currentDate);
				console.log(isInRange, timeSinceLastDate, oneDayInMillis, twoDaysInMillis);
				if (isInRange) {
					const newStreak = streak + 1;
					setStreak(newStreak);
					localStorage.setItem("streak", newStreak.toString());
					setStreakIncremented(true);
				} else if (timeSinceLastDate >= twoDaysInMillis && lastDate !== 0) {
					// Reset streak if more than 48 hours have passed
					setStreak(1);
					localStorage.setItem("streak", "1");
					setStreakBroken(true);
				} else if (lastDate !== 0) {
					setAlreadyPlayedToday(true);
				} else {
					setStreak(1);
					localStorage.setItem("streak", "1");
				}

				// Update the last date
				setDate(currentDate.getTime());
				localStorage.setItem("lastDate", currentDate.getTime().toString());

				setFinished(true);
				return;
			}

			setCurrentQuestion(currentQuestion + 1);
			setShowFeedback(false);
		}, 1000); // Wait for the animation to finish before showing the next question
	};

	return (
		<div className="relative">
			{!isFinished && (
				<>
					{showFeedback && (
						<div
							className={`${
								animation === "correct-animation" ? "bg-green-500" : "bg-red-500"
							} absolute top-20 w-24 h-24 flex justify-center items-center left-1/2 z-20 -translate-x-1/2`}
						>
							{animation === "correct-animation" ? <div className="checkmark ">✔</div> : <div className="cross ">✘</div>}
						</div>
					)}
					{!isStarted && <InitialCard onClick={handleClick} />}
					{isStarted && (
						<>
							<div className="text-white">
								<h1>
									Score: {score} / {currentQuestion}
								</h1>
							</div>
							<ChampionCard question={questions[currentQuestion]} onClick={handleAnswer} isShowingFeedback={showFeedback} />
						</>
					)}
				</>
			)}
			{isFinished && (
				<div>
					<div className="text-white flex justify-center h-80 items-center">
						<div>
							<h1 className="text-center mx-auto text-2xl">
								Congratulations! <br></br>
								Your final score is {score} / {questions.length}
								{isStreakBroken && (
									<p className="text-sm">
										You broke your streak! You did not play for more than 48 hours. <br></br> Play again tommorow to keep your streak!
									</p>
								)}
								{alreadyPlayedToday && <p className="text-sm">You already played today! Your streak was not incremented.</p>}
								{isStreakIncremented && <p className="text-sm">You increased your streak. Keep it up!</p>}
							</h1>
							<div>
								<div className="flex items-center justify-center">
									<span className="text-3xl">{streak}</span>
									<img src="flame.png" width={120}></img>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-col justify-center">
						{questions.map((question, index) => (
							<ChampionCardShow question={question} key={index} />
						))}
					</div>
				</div>
			)}
		</div>
	);
}

export default CardContainer;
