import Button from "./Button";

function ChampionCard({ question, onClick, isShowingFeedback }) {
	return (
		<div className="relative h-100">
			<h1 className="text-white text-center text-lg">{question.question}</h1>
			<div className="flex justify-center">
				<img src={question.imageUrl} width={150} />
			</div>
			<div className="flex justify-center flex-col">
				{!isShowingFeedback && (
					<>
						{question.options.map((option) => (
							<Button onClick={onClick} text={option} key={option} />
						))}
					</>
				)}
			</div>
		</div>
	);
}

export default ChampionCard;
