function Logo() {
	return (
		<div className="flex justify-center">
			<img src="lol-logo.png" width={80} />
			<span className="text-white text-2xl -ml-2 self-center">uiz</span>
		</div>
	);
}

export default Logo;
