import champions from "../champion.json";
import CardContainer from "./CardContainer";
import Logo from "./Logo";

const quizConfig = {
	championsQuestionsNumber: 15,
	championsQuestionsOptionsNumber: 4,
	championsImageRootUrl: "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/",
	disabledChampions: [
		"TEMP",
		/*"Fiora", "Amumu", "Heimerdinger", "Caitlyn", "Vi", "Jinx"*/
	],
};
const questions: any[] = [];
let randomChampions = getRandomChampions();
console.log(randomChampions);

// championsQuestionsNumber * championsQuestionsOptionsNumber
for (let i = 0; i < quizConfig.championsQuestionsNumber * quizConfig.championsQuestionsOptionsNumber; i += quizConfig.championsQuestionsOptionsNumber) {
	const correctChampion = randomChampions[i];

	// other options are the ones right after for championsQuestionsOptionsNumber - 1
	let otherOptions: any[] = [];
	for (let j = 1; j < quizConfig.championsQuestionsOptionsNumber; j++) {
		otherOptions.push(randomChampions[i + j]);
	}
	otherOptions.push(correctChampion);

	// shuffle the options
	otherOptions = otherOptions.sort(() => 0.5 - Math.random());

	questions.push({
		question: "Who is this champion?",
		imageUrl: quizConfig.championsImageRootUrl + correctChampion.id + "_0.jpg",
		options: otherOptions.map((champion) => champion.name),
		correctOption: correctChampion.name,
		answered: false,
		answeredCorrectly: false,
		answeredOption: "",
	});
}

function shuffle(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
		[array[i], array[j]] = [array[j], array[i]]; // swap elements
	}
	return array;
}

function getRandomChampions() {
	let keys = Object.keys(champions.data);
	keys = keys.filter((key) => !quizConfig.disabledChampions.includes(champions.data[key].name));

	// good randomization
	let shuffledKeys = shuffle(keys);

	return shuffledKeys.slice(0, quizConfig.championsQuestionsNumber * quizConfig.championsQuestionsOptionsNumber).map((key) => champions.data[key]);
}

function QuizContainer() {
	return (
		<div className="w-4/5 p-4 h-fit shadow-lg border-white border-opacity-5 border-solid border-[1px] bg-secondary mt-10 rounded-lg">
			<Logo />
			<hr className="mt-2 mb-2"></hr>
			<CardContainer questions={questions} />
		</div>
	);
}

export default QuizContainer;
