import QuizContainer from "./components/QuizContainer";

function App() {
	return (
		<div className="bg-primary min-h-screen pb-4 flex justify-center">
			<QuizContainer />
		</div>
	);
}

export default App;
